<div class="bc-content">
    <!-- Header -->
    <app-detalles-contrato-page-header [contrato]="_contract"></app-detalles-contrato-page-header>

    <!-- Mosaico de detalles de contrato -->
    <div class="bc-row bc-padding-1 bc-wrap bc-flex bc-direction-column bc-direction-lg-row">
        <div class="bc-col-12 bc-col-lg-8">
            <div class="bc-margin-bottom-2 bc-padding-1">
                <div class="Boton-regresar">
                    <bc-icon size="md" name="arrow-left" [ariaHidden]="true" (click)="goBack()" class="icono-regresar"></bc-icon>
                    <span class="texto-regresar">Volver atrás</span>
                </div>
            </div>
            <!-- Proyecto -->
            <div class="bc-row bc-margin-bottom-1">
                <div class="bc-col-12 bc-bg-light">
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Nombre del proyecto</b></small>
                            <p>{{_contract.contractName}}
                            <p>
                        </div>
                    </bc-card-container>
                </div>
            </div>
            <!-- Descripcion -->
            <div class="bc-row bc-margin-bottom-1">
                <div class="bc-col-12 bc-bg-light">
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Descripci&oacute;n del contrato</b></small>
                            <p>{{_contract.description}}</p>
                        </div>
                    </bc-card-container>
                </div>
            </div>
            <!-- Row 2: Acuerdo, familia, vigencia y estado -->
            <div class="bc-col-12 bc-margin-bottom-1">
                <div class="bc-row bc-justify-content-space-between bc-bg-white bc-wrap">
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Familia</b></small>
                            <p>
                                <ng-container *ngIf="_contract.family && _contract.family.length > 0; else noFamily">
                                    {{ _contract.family[0].nombre }}
                                </ng-container>
                                <ng-template #noFamily>
                                    No registra familia
                                </ng-template>
                            </p>

                        </div>
                    </bc-card-container>
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Tipo de acuerdo</b></small>
                            <p>{{_contract.agreementType}}</p>
                        </div>
                    </bc-card-container>
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Tipo de vigencia</b></small>
                            <p>{{_contract.typePeriodValidity}}</p>
                        </div>
                    </bc-card-container>
                    <bc-card-container cardType="default">
                        <div class="bc-padding-2">
                            <small><b>Estado</b></small>
                                <app-badge-estado title="Administrador solo puede cambiar de estado a Cerrado"
                                [clase]="'contrato'"
                                [estadoContrato]="_contract.status.charAt(0).toUpperCase() + _contract.status.slice(1).toLowerCase()"
                                [tipo]="'only'">
                            </app-badge-estado>
                            
                            <div>
                                <app-reportar-cambio-estado
                                    [textButton]="'Cambiar estado'"></app-reportar-cambio-estado>
                            </div>
                        </div>
                    </bc-card-container>
                </div>
            </div>

            <!-- Row 3: Fechas -->
            <div class="bc-col-12 bc-margin-bottom-1">
                <div class="bc-row bc-justify-content-space-between  bc-wrap bc-gap-2">
                    <app-card-fecha [mostrarAlerta]='false' [fecha]="_contract.initialDate"
                        [titulo]="'Fecha de entrada en vigencia'" class="bc-flex-fill bc-flex"></app-card-fecha>

                    <app-card-fecha [mostrarAlerta]='true' [fecha]='_contract.finalDate' [contract]="_contract"
                        [titulo]="'Fecha de vencimiento del contrato'" class="bc-flex-fill bc-flex"></app-card-fecha>

                    <app-card-fecha [mostrarAlerta]='false' [fecha]='_contract.tariffExpiry' [contract]="_contract"
                        [titulo]="'Fecha de vencimiento de tarifa'" class="bc-flex-fill bc-flex"></app-card-fecha>
                </div>
            </div>


            <!-- Row 4: Administrador y delegados -->
            <div class="bc-col-12 bc-margin-bottom-1">
                <div class="bc-row bc-justify-content-space-between bc-wrap bc-gap-2">
                    <!-- Cambiar por componente rehusable y parametrizable -->
                    <div class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white">
                        <small><b>Nombre del administrador</b></small>
                        <p>{{_contract.admin.name}}</p>
                        <div
                            class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300 bc-padding-bottom-2">
                            <div class="bc-flex bc-align-self-center">
                                <!-- Correo -->
                                <bc-icon size="xs" name="mail" aria-hidden="true" aria-label="mail"></bc-icon>
                                <div>
                                    <span #correoAdministrador>{{_contract.admin.email}}</span>
                                </div>
                            </div>
                            <span id="copy-btn">
                                <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                                    [customIcon]="'documents'" (click)="copiarCorreoAdministrador()">
                                </bc-icon-button>
                            </span>
                        </div>

                        <div>
                            <app-novedad-administrador [contrato]="_contract"
                                [textButton]="'Reportar novedad de administrador'"></app-novedad-administrador>
                        </div>


                    </div>
                    <!-- Delegado -->
                    <div *ngIf="this.userData.rol == 'ADMINISTRADOR DE CONTRATOS' && (_contract.status == 'PUBLICADO' || _contract.status == 'VENCIDO')">
                        <div class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white">
                            <small><b>Administrador encargado</b></small>
                            <div class="bc-flex bc-align-self-center">Persona de tu equipo que te apoyará<br>
                                en la gestión del contrato </div>
                        <div
                            class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300 bc-padding-bottom-2">
                            <div class="bc-flex bc-align-self-center">
                                
                                
                                <div>
                                    <span #correoAdministrador></span>
                                </div>
                            </div>
                            <span >
                                <span class="empty-space"> </span>
                            </span>
                        </div>
                            <div *ngIf="this.userData.rol == 'ADMINISTRADOR DE CONTRATOS' && (_contract.status == 'PUBLICADO' || _contract.status == 'VENCIDO')">
                                <button  (click)="abrirEnNuevaPestana()" class="boton-detalles">Asigna un administrador encargado</button>
                            </div>
                            </div>
                    </div> 

                </div>
            </div>
        </div>

        <div class="bc-col-12 bc-col-lg-4">
            <div 
                class="bc-margin-bottom-1 bc-bg-white bc-padding-2"
                *ngIf="_contract.status == 'PUBLICADO' || _contract.status == 'VENCIDO' || _contract.status == 'MODIFICACION DEL BORRADOR'"
            >
                <app-float-menu-aba
                    description="¿Tienes inconvenientes con este servicio?"
                    iconDescription="info"
                    [options]="[{ id: '1', label: 'Radicar escalamiento', icon: 'plus', link: this.urlPowerApps + _contract.idContract }]"
                />
            </div>
            <div class="bc-bg-light bc-margin-bottom-1">
                <bc-card-container cardType="default">
                    <div class="bc-padding-2">
                        <small><b>&Aacute;rea responsable</b></small>
                        <ng-container
                            *ngIf="_contract.responsibleArea && _contract.responsibleArea.length > 0; else noAreaResponsable">
                            <p>{{_contract.responsibleArea}}</p>
                        </ng-container>
                        <ng-template #noAreaResponsable>
                            No registra area responsable
                        </ng-template>
                    </div>
                </bc-card-container>
            </div>

            <div
                class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white bc-margin-bottom-1">

                <small><b>Nombre del comercial</b></small>

                <div
                    class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300">
                    <div class="bc-flex bc-align-self-center">
                        <!-- Correo Comercial -->
                        <div class="bc-margin-left-1">

                            <ng-container
                                *ngIf="_contract.providerEntity.commercial_name && _contract.providerEntity.commercial_name.length > 0; else noComercialname">
                                <p>{{_contract.providerEntity.commercial_name}}</p>
                            </ng-container>
                            <ng-template #noComercialname>
                                No registra nombre comercial
                            </ng-template>

                        </div>
                    </div>

                </div>




                <div
                    class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300">
                    <div class="bc-flex bc-align-self-center">
                        <!-- Correo Comercial -->
                        <div class="bc-margin-left-1">

                            <ng-container
                                *ngIf="_contract.providerEntity.commercialMail && _contract.providerEntity.commercialMail.length > 0; else noCorreo">
                                <span #correoProveedorComercial>{{_contract.providerEntity.commercialMail}}</span>
                            </ng-container>

                            <ng-template #noCorreo>
                                No registra correo
                            </ng-template>

                        </div>
                    </div>
                    <span id="copy-btn">
                        <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                            [customIcon]="'documents'" (click)="copiarCorreoProveedorComercial()">
                        </bc-icon-button>
                    </span>
                </div>
                <div
                    class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300">
                    <div class="bc-flex bc-align-self-center">
                        <ng-container
                            *ngIf="_contract.providerEntity.commercialPhone && _contract.providerEntity.commercialPhone.length > 0; else noTelefono">

                            <div class="bc-margin-left-1">
                                <span #correoAdministrador>{{_contract.providerEntity.commercialPhone}}</span>
                            </div>

                        </ng-container>
                        <ng-template #noTelefono>
                            <div class="bc-margin-left-1">
                                No registra teléfono
                            </div>

                        </ng-template>

                    </div>
                    <span id="copy-btn-correo">
                        <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                            [customIcon]="'documents'" (click)="copiarCorreoAdministrador()">
                        </bc-icon-button>
                    </span>
                </div>
            </div>

            <div
                class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white bc-margin-top-1">

                <div class="bc-flex bc-justify-content-space-between bc-align-self-center">
                    <div class="bc-flex bc-align-self-center">

                        <small>
                            <b>
                                ¿Esta información está desactualizada?
                            </b>
                        </small>
                    </div>
                </div>
                <span>
                    Invita al proveedor a actualizar constantemente el contacto comercial a través de este
                    <a href="http://bancolombia.supplier.ariba.com/" target="_blank" class="bc-button">link</a>

                </span>

                <!-- <div class="bc-margin-top-1">
                    <small>
                        <b>
                            ¿No sabes cómo hacerlo?
                        </b>

                    </small>

                </div>
                <span>
                    Conoce aquí el instructivo.
                </span>
                <button (click)="descargarPDF()">Descargar PDF</button> -->

                  
                  

            </div>


            <div
                class="bc-padding-2 bc-flex-fill bc-align-self-stretch bc-align-self-center bc-bg-white bc-margin-top-1">
                <small><b>Nombre del negociador</b></small>
                <p>{{_contract.negotiator.name}}</p>
                <div
                    class="bc-flex bc-justify-content-space-between bc-align-self-center bc-text-brand-sequential-N-300">
                    <div class="bc-flex bc-align-self-center">
                        <!-- <bc-icon size="xs" name="mail" aria-hidden="true" aria-label="mail"></bc-icon> -->
                        <div class="bc-margin-left-1">
                            <span #correoNegociador>{{_contract.negotiator.email}}</span>
                        </div>
                    </div>
                    <span id="copy-btn-correo">
                        <bc-icon-button [type]="'solid'" [color]="'white'" [shadow]=false [size]="'small'"
                            [customIcon]="'documents'" (click)="copiarCorreoNegociador()">
                        </bc-icon-button>
                    </span>
                </div>
            </div>

            <div class="bc-text-center bc-margin-top-2">
                <p>¿Quieres ver más información de este contrato? </p>
                <div class="bc-margin-top-1">
                    <div class="boton-ariba-contratos">
                        <a [href]="['http://bancolombia.sourcing.ariba.com/ad/viewDocument?ID=' +  _contract.idContract] "
                            target="_blank" class="bc-button">Ingresa a Ariba</a>
                    </div>
                </div>
                <!-- <iframe id="miIframe" src="https://s1.ariba.com/Sourcing/Main/aw?awh=r&awssk=VShd1foNvYOfYLLw&realm=bancolombia&awrdt=1#b0" allow="geolocation; microphone; camera"></iframe> -->
            </div>

        </div>


    </div>
    <div class="bc-padding-4 bc-margin-bottom-6">
        <h4>Contratos asociados</h4>
        <div class="bc-py-2">
            <h5>Acuerdo</h5>
            <div class="bc-text-center bc-py-2">
                <span class="bc-text-brand-sequential-N-300" *ngIf="contractMaster==undefined; else mostrarPadre">Sin
                    contratos asociados</span>
                <ng-template #mostrarPadre>

                    <app-list-item-contrato [contract]="contractMaster"> </app-list-item-contrato>
                </ng-template>
            </div>
        </div>
        <!-- <div class="bc-py-2">
            <h5>Subacuerdo</h5>
            <div class="bc-text-center bc-py-2">
                <span class="bc-text-brand-sequential-N-300"
                    *ngIf="contractsChilds.length==0; else mostrarHijos">Sin contratos asociados</span>
                <ng-template #mostrarHijos>
                    <app-list-item-contrato
                    *ngFor="let contractChild of contractsChilds"
                        [contract]="contractChild" [class]="'bc-margin-bottom-2'"></app-list-item-contrato>
                </ng-template>
            </div>
        </div> -->



        <div class="bc-py-2">
            <h5>Subacuerdo</h5>
            <div>
                <span class="bc-text-brand-sequential-N-300" *ngIf="contractsChilds.length == 0; else mostrarHijos">
                    <div class="bc-text-center">
                        Sin
                    contratos asociados
                    </div>
                    </span>
                <ng-template #mostrarHijos>
                    <div class="sort-buttons-container">
                    <div class="bc-row">
                        <div class="bc-col-8">
                            
                        </div>
                        <div class="bc-col-2">
                            <button class="sort-button" (click)="ordenarPorFecha('initialDate')">
                                <b>
                                    <p>
                                        Ordenar por fecha 
                                    </p>
                                </b>
                                <bc-icon size="xs" name="arrow-up"></bc-icon>
                                <bc-icon size="xs" name="arrow-down"></bc-icon>
                            </button>
                        </div>
                        <div class="bc-col-2">
                            <button class="sort-button" (click)="ordenarPorFecha('finalDate')">
                                <b>
                                    <p>
                                        Ordenar por fecha 
                                    </p>
                                </b>
                                <bc-icon size="xs" name="arrow-up"></bc-icon>
                                <bc-icon size="xs" name="arrow-down"></bc-icon>
                            </button>
                        </div>
                      </div>
                </div>
                <div class="bc-margin-top-2">
                    <app-list-item-contrato *ngFor="let contractChild of contractsChilds" [contract]="contractChild"
                        [class]="'bc-margin-bottom-2'"></app-list-item-contrato>
                </div>
                </ng-template>
            </div>
        </div>

    </div>